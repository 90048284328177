import ProjectDisplay from '../ProjectDisplay/ProjectDisplay';
import Selectable from '../Selectable/Selectable';
import './TimelineProject.css';

export default function TimelineProject({slug, technology, thumbnail, isHighlight, experience, onClick, isSelected, isLeft, order}) {
    return <div className={`TimelineProject ${isSelected ? 'selected' : ''} ${order}${isHighlight ? ' is-highlight' : ''}`}>
        {!isLeft ? <div className='filler'></div> : <ProjectDisplay slug={slug}
                                                technology={technology}
                                                thumbnail={thumbnail}
                                                isHighlight={isHighlight}
                                                experience={experience}
                                                isLeft={isLeft}
                                                className={isSelected ? 'selected' : ''}
                                                onClick={onClick}/>}
        <div className='timeline-project-small-box'>
            <div className='timeline-project-small-connector-container'>
                <div className='timeline-project-small-connector'></div>
            </div>
            <div className='timeline-project-full-node-connectors-container'>
                <div className={`timeline-project-small-connector-perpendicular ${isLeft ? 'pre' : ''}`}></div>
                <div className='timeline-project-full-node'>
                    <Selectable isSelected={isSelected} width="50px" height="50px"></Selectable>
                </div>
                <div className={`timeline-project-small-connector-perpendicular ${!isLeft ? 'post' : ''}`}></div>
            </div>
            <div className='timeline-project-small-connector-container'>
                <div className='timeline-project-small-connector'></div>
            </div>
        </div>
        {isLeft ? <div className='filler'></div> : <ProjectDisplay slug={slug}
                                                technology={technology}
                                                thumbnail={thumbnail}
                                                isHighlight={isHighlight}
                                                experience={experience}
                                                isLeft={isLeft}
                                                className={isSelected ? 'selected' : ''}
                                                onClick={onClick}/>}
    </div>;
}