import { Link } from 'react-router-dom';
import Selectable from '../Selectable/Selectable';
import './HireMe.css';

export default function HireMe({ isLeft }) {
    return <div className={`HireMe`}>
        {!isLeft ? <div className='filler'></div> : <Selectable width="150px" height="150px" bracketedMode={true} className='hire-me-display'>
            <Link to='/hire-me' className='hireable'>I'm open to new positions!<br/>Hire me!</Link>
        </Selectable>}
        <div className='timeline-project-small-box'>
            <div className='timeline-project-small-connector-container'>
                <div className='timeline-project-small-connector'></div>
            </div>
            <div className='timeline-project-full-node-connectors-container'>
                <div className={`timeline-project-small-connector-perpendicular ${isLeft ? 'pre' : ''}`}></div>
                <div className='timeline-project-full-node'>
                    <Selectable width="50px" height="50px"></Selectable>
                </div>
                <div className={`timeline-project-small-connector-perpendicular ${!isLeft ? 'post' : ''}`}></div>
            </div>
            <div className='timeline-project-small-connector-container'>
                <div className='timeline-project-small-connector'></div>
            </div>
        </div>
        {isLeft ? <div className='filler'></div> : <Selectable width="150px" height="150px" bracketedMode={true} subClassName='hire-me-display'>
            <Link to='/hire-me' className='hireable'>I'm open to new positions!<br/>Hire me!</Link>
        </Selectable>}
    </div>;
}