import config from '../config.json';

export default class Media {
    constructor(attributes) {
        this.name = attributes.name;
        this.altText = attributes.altText;
        this.caption = attributes.caption;
        this.mime = attributes.mime;
        this.url = `${config.url}${attributes.url}`;
        this.hasOtherFormats = !!attributes.formats && Object.keys(attributes.formats).length;
        this.thumbnailUrl = this.hasOtherFormats && attributes.formats.thumbnail ? `${config.url}${attributes.formats.thumbnail.url}` : null;
        this.largeUrl = this.hasOtherFormats && attributes.formats.large ? `${config.url}${attributes.formats.large.url}` : null;
        this.mediumUrl = this.hasOtherFormats && attributes.formats.medium ? `${config.url}${attributes.formats.medium.url}` : null;
        this.smallUrl = this.hasOtherFormats && attributes.formats.small ? `${config.url}${attributes.formats.small.url}` : null;
        this.isSquare = attributes.width / attributes.height === 1;
    }

    isImage() {
        return this.mime.includes("image");
    }

    isVideo() {
        return this.mime.includes("video");
    }
}
