import { Link } from 'react-router-dom';
import Selectable from '../Selectable/Selectable';
import './ProjectDisplay.css';

export default function ProjectDisplay({ slug, technology, thumbnail, isHighlight, experience, isLeft, className = '', style = {}}) {
    return (<div className={`ProjectDisplay ${className}`} style={style}>
        {!isLeft ? <div className='endpoint'></div> : ''}
        <Selectable bracketedMode={true}>
            <Link className={`mini-info ${isLeft ? 'left' : 'right'}${isHighlight ? ' is-highlight' : ''}${!thumbnail.isSquare ? ' rectangle-img' : ''}`} style={{ backgroundImage: `url(${thumbnail.smallUrl})` }} to={`/project/${slug}`} >
                <div className='mini-info-text'>
                    <div className='mini-info-top'>
                        {technology && technology.media ? <div className='tech-image-container'><img alt={technology.name} src={technology.media.thumbnailUrl} /></div> : ''}
                    </div>
                    <div className='mini-info-bottom'>
                        <div className='role'>{experience.title}</div>
                        <div className='company'>
                            <span className='company-text'>{experience.company}</span> <img alt='' src={experience.media.thumbnailUrl} />
                        </div>
                    </div>
                </div>
            </Link>
        </Selectable>
        {isLeft ? <div className='endpoint'></div> : ''}
    </div>);
}