import Media from './Media';

export default class Experience {
    constructor({ id, title, type, startDate, endDate, company, companyUrl, description, projects, media }) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.startDate = startDate;
        this.endDate = endDate || 'Present';
        this.company = company;
        this.companyUrl = companyUrl;
        this.description = description;
        this.media = media ? new Media(media.data.attributes) : null;
        this.isExperienceObject = true;
    }
}
