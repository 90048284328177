import Media from './Media';

export default class WebsiteInfo {
    constructor({ title, subtitle, contact, favicon, bannerImages, copyright, copyrightYear, hireMe }) {
        this.title = title;
        this.subtitle = subtitle;
        this.contact = contact;
        this.favicon = new Media(favicon.data.attributes);
        this.bannerImages = !!bannerImages ? bannerImages.data.map(item => new Media(item.attributes)) : [];
        this.copyright = copyright;
        this.copyrightYear = copyrightYear;
        this.hireMe = hireMe;
    }
}
