import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";

import './Layout.css';
import Copyright from '../../components/Copyright/Copyright';
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';
import Socials from '../../components/Socials/Socials';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Selectable from '../../components/Selectable/Selectable';


function Layout() {
    const { websiteInfo, pages, socials } = useContext(WebsiteInfoContext);
    const [selectedTab, setSelectedTab] = useState(window.location.pathname.split('/')[1] || null);
    const location = useLocation();
    const checkSelection = (name) => selectedTab === name;
    useEffect(() => {
        setSelectedTab(location.pathname.split("/")[1] || null);
    }, [location]);

    return (
        <div className="Layout">
            <header className="header">
                <div className='banner'>
                    {websiteInfo.bannerImages.map((image, i) => <div key={i} className='banner-image-container'><img alt='' src={image.mediumUrl} /></div>)}
                </div>
            </header>
            <nav>
                <ul className="nav">
                    <li>
                        <Link className='logo' to="/">
                            <Selectable isSelected={checkSelection(null)||checkSelection('project')} className='nav-button-container' subClassName='nav-button'>
                                Projects
                            </Selectable>
                        </Link>
                    </li>
                    {pages.map(page => <li key={`link-${page.link}`}>
                        <Link to={`/${page.link}`} onClick={() => setSelectedTab(page.link)}>
                            <Selectable isSelected={checkSelection(page.link)} className='nav-button-container' subClassName='nav-button'>
                                {page.title}
                            </Selectable>
                        </Link>
                    </li>)}
                </ul>
            </nav>
            <div className="content">
                <Outlet />
            </div>
            <footer className="footer">
                <a href={`mailto:${websiteInfo.contact}`}><FontAwesomeIcon icon="fa-solid fa-envelope" /> Email</a>
                <div className='copyrights'>
                    <Copyright owner={websiteInfo.copyright} startYear={websiteInfo.copyrightYear} />
                    <div>Made with ❤ by <a href='https://dosaki.net/'>this guy</a></div>
                </div>
                <Socials socials={socials} />
            </footer>
        </div>
    );
}

export default Layout;
