import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';
import TimelineProject from '../../components/TimelineProject/TimelineProject';

import './Timeline.css';
import HireMe from '../../components/HireMe/HireMe';

export default function Timeline({ projects }) {
    const navigate = useNavigate();
    const select = (project) => {
        navigate(`/project/${project.slug}`);
    };
    const { websiteInfo } = useContext(WebsiteInfoContext);

    return (<>
        <div className='Timeline'>
            <div className='square' />
            <div className='square' />
            <div className='square pre' />

            {websiteInfo.hireMe ? <HireMe /> : ''}

            {[...projects].reverse()
                .map((project, i) => <>
                    <div className={`mobile-connector-container${!websiteInfo.hireMe ? ' no-hire-me' : ''} ${!i ? ' first' : ''}`}
                        key={`${project.title}-${project.date}-connector`}>
                        <div className='mobile-connector'></div>
                    </div>
                    <TimelineProject {...project}
                        order={!i ? 'first' : i === projects.length - 1 ? 'last' : 'middle'}
                        isLeft={i % 2 === 0}
                        key={`${project.title}-${project.date}`}
                        onClick={() => select(project)} />
                </>)}

            <div className='square post' />
            <div className='square' />
            <div className='square' />
        </div>
    </>
    );
}