import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import config from '../config.json';
import StaticPage from '../pages/StaticPage/StaticPage';
import Page from '../model/Page';
import WebsiteInfo from '../model/WebsiteInfo';
import { get } from '../utils/fetch-utils';

import './App.css';
import Layout from '../pages/Layout/Layout';
import WebsiteInfoContext from '../contexts/WebsiteInfoContext';
import Social from '../model/Social';
import Project from '../model/Project';
import Timeline from '../pages/Timeline/Timeline';
import ProjectInfo from '../pages/ProjectInfo/ProjectInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fetchInfo = async (endpoint, setter, Class, sorterAttr) => {
    const info = await get(`${config.url}${endpoint}`);
    const _objects = info.data.map(o => new Class(o.attributes));
    let _sortedObjects = sorterAttr ? _objects.sort((a, b) => {
        if(a[sorterAttr] === "Present"){
            return -2;
        }
        if (a[sorterAttr] < b[sorterAttr]) {
            return -1;
        }
        if (a[sorterAttr] > b[sorterAttr]) {
            return 1;
        }
        return 0;
    }) : _objects;
    setter([..._sortedObjects]);
}

function App() {
    const [websiteInfo, setWebsiteInfo] = useState();
    const [pages, setPages] = useState([]);
    const [socials, setSocials] = useState([]);
    const [projects, setProjects] = useState([]);
    const context = {
        websiteInfo,
        setWebsiteInfo,
        pages,
        setPages,
        socials,
        setSocials,
        projects,
        setProjects
    };


    useEffect(() => {
        const getWebsiteData = async () => {
            const websiteInformation = await get(`${config.url}/api/website-info?populate=bannerImages&populate=favicon`);
            const _websiteInfo = new WebsiteInfo(websiteInformation.data.attributes);
            setWebsiteInfo(_websiteInfo);
            const faviconElem = document.getElementById("favicon");
            faviconElem.href = _websiteInfo.favicon.url;
            faviconElem.type = _websiteInfo.favicon.mime;
        };
        getWebsiteData();
    }, []);
    useEffect(() => {
        fetchInfo("/api/pages?populate=*", setPages, Page, "order");
    }, []);
    useEffect(() => {
        fetchInfo("/api/socials", setSocials, Social);
    }, []);
    useEffect(() => {
        fetchInfo("/api/projects?populate[0]=media&populate[1]=thumbnail&populate[2]=experience.media&populate[3]=technology.icon", setProjects, Project, "date");
    }, []);

    if (!websiteInfo || !pages) {
        return (
            <div className="Loading">
                <FontAwesomeIcon icon="fa-spinner" />
            </div>
        );
    }

    return (
        <WebsiteInfoContext.Provider value={context}>
            <Router>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path='' element={<Timeline projects={projects} />} />
                        {pages.map(page => <Route key={`route-${page.link}`} path={page.link} element={<StaticPage {...page} />} />)}
                        <Route path='project/:slug' element={<ProjectInfo projects={projects} />} />
                    </Route>
                </Routes>
            </Router>
        </WebsiteInfoContext.Provider>
    );
}

export default App;
