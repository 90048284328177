import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import RehypeVideo from 'rehype-video';
import { Link, useParams } from "react-router-dom";
import { useContext } from 'react';
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProjectInfo.css';

const formatDate = (date) => {
    return new Date(date).toLocaleString(undefined, { month: 'long', year: 'numeric' });
};


export default function ProjectInfo() {
    const { slug } = useParams();
    const { projects } = useContext(WebsiteInfoContext);

    const project = projects.find(p=>p.slug === slug);
    if(!project){
        return `Couldn't find that project...`
    }
    return (<div className='ProjectInfo'>
        <div className='project-info-container'>
            <h1><Link to='/'><FontAwesomeIcon icon="fa-circle-left" /></Link> {project.title}</h1>
            <h2>{formatDate(project.date)}</h2>
            <div className='description'>
                <ReactMarkdown rehypePlugins={[rehypeRaw, RehypeVideo]}>{project.description}</ReactMarkdown>
            </div>
        </div>
    </div>);
}