import config from '../config.json';
import { toLink } from '../utils/string-utils';
import Media from './Media';

export default class Page {
    constructor(attributes) {
        this.title = attributes.title;
        this.order = attributes.order;
        this.content = attributes.content;
        this.isCollection = !!attributes.dynamicCollection;
        this.collectionStyle = this.isCollection ? attributes.dynamicCollectionStyle : null;
        this.collectionUrl = this.isCollection ? `${config.url}/api/${attributes.dynamicCollection}?populate=media` : null;
        this.singleItemUrlTemplate = this.isCollection ? `${config.url}/api/${attributes.dynamicCollection}/{{:id}}?populate=media` : null;
        this.media = !this.isCollection ? (attributes.media.data || []).map(item => new Media(item.attributes)) : [];
        this.link = toLink(attributes.title);
        this.maxWidth = attributes.maxWidth;
    }
}
