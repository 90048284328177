import Experience from './Experience';
import Media from './Media';

export default class Project {
    constructor({ slug, title, media, description, date, thumbnail, isHighlight, experience, technology }) {
        this.slug = slug;
        this.title = title;
        this.description = description;
        this.media = !!media ? media.data.map(item => new Media(item.attributes)) : [];
        this.date = date || new Date().toISOString();
        this.isHighlight = isHighlight;
        this.experience = experience && experience.data ? new Experience(experience.data.attributes) : null;
        this.thumbnail = thumbnail ? new Media(thumbnail.data.attributes) : null;
        this.technology = technology && technology.data ? {
            name: technology.data.attributes.name,
            media: technology.data.attributes.icon ? new Media(technology.data.attributes.icon.data.attributes) : null
        } : null;
    }
}
