import React from 'react';

const WebsiteInfoContext = React.createContext({
    websiteInfo: {},
    setWebsiteInfo: () => {},

    pages: [],
    setPages: ()=>{},
    
    socials: [],
    setSocials: ()=>{},

    projects: [],
    setProjects: ()=>{}
});

export default WebsiteInfoContext;